import { MutationResult } from '@apollo/client'
import { AssetGroupCount, SelectedScan, AssetData, Surgery } from 'common/types'
import { createContext } from 'react'

export const defaultRfSpongeCountContext: RfSpongeCountContextProps = {
  rfSpongeAssetGroupCount: [],
  saveMemoNote: () => {},
  addAssetGroupsCountMutation: {
    data: undefined,
    error: undefined,
    loading: false,
  } as RfSpongeCountContextProps['addAssetGroupsCountMutation'],
  matchToAssetGroup: () => {},
  updateAssetGroupCounts: () => {},
  addAssetGroupsCount: () => {},
}

export const RfSpongeCountContext = createContext<RfSpongeCountContextProps>(
  defaultRfSpongeCountContext
)

export interface RfSpongeCountContextProps {
  rfSpongeAssetGroupCount: AssetGroupCount[]
  saveMemoNote: () => void
  addAssetGroupsCountMutation: MutationResult<{
    addAssetGroupsCount?: {
      assetGroupCount?: AssetGroupCount | undefined
    }
  }>
  matchToAssetGroup?: (selectedScans: SelectedScan[]) => void
  updateAssetGroupCounts: (
    id: AssetData['_id'],
    updates: Partial<AssetGroupCount>
  ) => void
  addAssetGroupsCount?: () => void
}

export interface RfSpongeCountProviderProps {
  children: React.ReactNode
  surgery: Surgery
}

export interface RfSpongeCountProviderValue {
  rfSpongeAssetGroupCount: AssetGroupCount[]
  setRfSpongeAssetGroupCount: React.Dispatch<
    React.SetStateAction<AssetGroupCount[]>
  >
  spongeAssetGroups: AssetData[]
  addAssetGroupsCountMutation: MutationResult<{
    addAssetGroupsCount?: {
      assetGroupCount?: AssetGroupCount | undefined
    }
  }>
  addAssetGroupsCount: () => void
}

export interface RfSpongeCountPanelProps {
  label: string
  isProcedureSubmitted: boolean
  value: string | number
  spongeCount?: number
  disableAll?: boolean
  handleDelete?: () => void
  isLoading?: boolean
  isManualAddition: boolean
  assetGroup: AssetData
  assetTray: AssetData['assetTray']
  surgeryId: Surgery['_id']
  printMode?: boolean
}
