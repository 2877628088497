import {
  Skeleton,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Select,
  FormControl,
  MenuItem,
  FormHelperText,
} from '@mui/material'
import { CameraAlt, FlipCameraIos, Replay } from '@mui/icons-material'

import Modal from 'components/molecules/Modal/Modal'
import { LoadingButton } from 'components/mui'
import useConfirmTrayImageModal from './ConfirmTrayImageModal.logic'

import './ConfirmTrayImageModal.scss'
import classNames from 'classnames'
import { useSPD } from 'views/SPDLayout/SPD/SPD.context'
import { useEffect } from 'react'
import { ListOfSurgeries } from '../ListOfSurgeries/ListOfSurgeries'
import { ItemResponse, Surgery } from 'common/types'
import Provider from 'lib/apollo'
import SurgeryBox from 'components/molecules/SurgeryBox/SurgeryBox'
import { useListOfSurgeries } from '../ListOfSurgeries/ListOfSugeries.logic'

interface ConfirmTrayImageModalProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

const ConfirmTrayImageModal = ({
  open,
  onClose,
  onConfirm,
}: ConfirmTrayImageModalProps) => {
  const {
    isCapturing,
    handleReset,
    image,
    setIsCapturing,
    videoRef,
    cameras,
    activeCamera,
    isCameraMenuOpen,
    handleToggleCameraMenu,
    handleSelectCamera,
    handleUpdateTrayItem,
    isSubmitting,
    handleCapture,
    activeStep,
    handleNext,
    handleBack,
    handleLocationChange,
    handleSurgeryChange,
    selectedSurgery,
    locationsWithParents,
    findLocation,
    selectedLocationId,
    updatingParentTrayItem,
    shouldNextBeDisabled,
  } = useConfirmTrayImageModal({ onClose, onConfirm })
  const { onlyUseManualWorkflow, newSubTrayImg, trayData, trayDetails } =
    useSPD()
  const { caseInformation, isNurse } = useListOfSurgeries({
    traySurgeryId: selectedSurgery?._id || trayDetails?.surgeryId || '',
  })

  useEffect(() => {
    if (onlyUseManualWorkflow) {
      setIsCapturing(true)
    } else {
      setIsCapturing(false)
    }
  }, [onlyUseManualWorkflow, setIsCapturing])

  useEffect(() => {
    if (!newSubTrayImg) {
      setIsCapturing(true)
    }
  }, [newSubTrayImg, setIsCapturing])

  const steps = ['Image Selection', 'Location', 'Surgery', 'Confirmation']

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return !isCapturing && newSubTrayImg ? (
          <>
            <Typography variant="body1" className="description">
              Would you like to use the current tray image or capture a new one?
            </Typography>

            <img src={newSubTrayImg} alt="tray" className="tray_image" />

            <div className="button-container">
              <LoadingButton
                onClick={handleUpdateTrayItem}
                loading={isSubmitting || updatingParentTrayItem}
              >
                Use Current Image
              </LoadingButton>
              <LoadingButton onClick={() => setIsCapturing(true)}>
                Capture New Image
              </LoadingButton>
            </div>
          </>
        ) : (
          <div className="camera-container">
            <div className="camera-select_container">
              <LoadingButton
                loading={false}
                disabled={false}
                onClick={handleToggleCameraMenu}
                sx={{ zIndex: 9999 }}
              >
                <FlipCameraIos />
              </LoadingButton>

              <div
                className="camera-options"
                style={{
                  display: isCameraMenuOpen ? 'flex' : 'none',
                  opacity: isCameraMenuOpen ? 1 : 0,
                }}
              >
                {cameras.map((camera: any) => (
                  <div
                    key={camera.deviceId}
                    className="camera"
                    onClick={() => handleSelectCamera(camera)}
                    style={{
                      backgroundColor:
                        camera.deviceId === activeCamera?.deviceId
                          ? '#5035ED'
                          : '#BCB7E1',
                      color:
                        camera.deviceId === activeCamera?.deviceId
                          ? 'white'
                          : 'var(--text-primary)',
                    }}
                  >
                    <p className="label">{camera.label}</p>
                    <p className="type">{camera.cameraType}</p>
                  </div>
                ))}
              </div>
            </div>

            <LoadingButton className="capture" onClick={handleCapture}>
              {image ? (
                <Replay className="icon" />
              ) : (
                <CameraAlt className="icon" />
              )}
            </LoadingButton>

            {image && <img src={image} alt="tray" className="feed" />}

            <div className={classNames('feed-container', { hidden: image })}>
              <Skeleton className="skeleton" variant="rounded" />
              <video
                className="feed"
                ref={videoRef}
                autoPlay
                muted
                playsInline
              />
            </div>
          </div>
        )
      case 1:
        return (
          <div className="location-step">
            <Typography variant="h6">
              Please select the storage location for the tray:
            </Typography>

            <FormControl fullWidth>
              <Select
                fullWidth
                data-testid="choose-tray-location-modal_picker"
                variant="outlined"
                displayEmpty
                renderValue={(selected) => {
                  if (!selected) {
                    return (
                      <Typography variant="body1" color="gray">
                        Select Tray Storage Location (required)
                      </Typography>
                    )
                  }

                  return findLocation(selected as number)?.name
                }}
                value={selectedLocationId}
                defaultValue={trayData?.trayItem.locationId}
                onChange={handleLocationChange}
              >
                <MenuItem disabled value="">
                  Select Tray Storage Location
                </MenuItem>

                {locationsWithParents.map((location) => (
                  <MenuItem
                    data-testid="hardware-menuitem"
                    style={{ cursor: 'pointer', fontSize: 16 }}
                    key={location.id}
                    value={location.id}
                  >
                    {location.name},{' '}
                    {findLocation(location.parentLocationId as number)?.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText data-testid="choose-tray-location-modal_picker_helper-text">
                You can create new locations from the location management
                dashboard
              </FormHelperText>
            </FormControl>
          </div>
        )
      case 2:
        return (
          <div className="surgeries-step">
            <Provider>
              <ListOfSurgeries
                traySurgeryId={
                  selectedSurgery?._id || trayDetails?.surgeryId || ''
                }
                handleSurgeryChange={handleSurgeryChange}
                trayData={trayData?.trayItem as ItemResponse}
              />
            </Provider>
          </div>
        )
      case 3:
        return (
          <div className="confirmation-step">
            <Typography variant="h3">
              Please confirm your selections:
            </Typography>
            <div className="confirmation-details">
              {image ? (
                <img src={image} alt="Selected tray" className="tray_image" />
              ) : (
                <Typography variant="body1" className="description">
                  Please capture a tray image before confirming
                </Typography>
              )}
              <Typography>
                <b>Location:</b>{' '}
                {
                  findLocation(
                    selectedLocationId ?? trayData?.trayItem.locationId ?? 0
                  )?.name
                }
              </Typography>
              <Typography>
                <b>Surgery:</b>
              </Typography>
              {(selectedSurgery || trayDetails?.surgeryDetails) && (
                <SurgeryBox
                  surgery={
                    (selectedSurgery || trayDetails?.surgeryDetails) as Surgery
                  }
                  caseInformation={caseInformation}
                  onClick={() => {}}
                  isNurse={isNurse}
                  isSelected={true}
                  disableSelect={true}
                />
              )}
            </div>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => {
        setIsCapturing(false)
        handleReset()
        onClose()
      }}
    >
      <div className="confirm-tray-image-modal">
        <div className="header-box">
          <CameraAlt />
          <Typography variant="h3">Confirm Tray Details</Typography>
        </div>

        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {renderStepContent()}

        <div className="bottom-buttons-container">
          {activeStep > 0 && (
            <LoadingButton onClick={handleBack} variant="outlined">
              Back
            </LoadingButton>
          )}

          {activeStep === steps.length - 1 ? (
            <LoadingButton
              onClick={handleUpdateTrayItem}
              disabled={!image}
              loading={isSubmitting || updatingParentTrayItem}
            >
              Confirm All Changes
            </LoadingButton>
          ) : (
            <LoadingButton disabled={shouldNextBeDisabled} onClick={handleNext}>
              Next
            </LoadingButton>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmTrayImageModal
