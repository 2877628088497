import classNames from 'classnames'
import {
  AssetGoodConditionCheck,
  SamplePreparationType,
  Scan,
} from 'common/types'
import { createGroupingKey } from 'lib/utils/data'
import { Box, Typography, Snackbar, Grid } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import './StoredAsset.scss'
import { DeleteAssetDialog } from 'components/DeleteAssetDialog'
import { AssetDetailsCard } from 'views/assets/AssetDetailsCard/AssetDetailsCard'
import { getAssetTypeLabel } from 'lib/utils/getAssetTypeLabel'
import { DispositionedAssetDetails } from '../DispositionedAssetDetails/DispositionedAssetDetails'
import { StoredAssetProps } from './StoredAsset.types'
import { useStoredAssetLogic } from './StoredAsset.logic'
import { EditAssetButton } from '../EditAssetButton/EditAssetButton'
import AssetDetailsStartIcon from 'components/molecules/AssetDetailsStartIcon/AssetDetailsStartIcon'
import MediaUpload from '../MediaUpload/MediaUpload'
import { WASTED_REASON_LIST, EXPLANTED_REASON_LIST } from 'common/disposition'

function StoredAsset({
  assetData,
  surgeryId,
  isProcedureSubmitted,
  isExplanted = false,
  groupedAssetData,
  printMode = false,
}: StoredAssetProps) {
  const {
    assetType,
    assetTray,
    associatedCount,
    canDelete,
    singleConsumablesCount,
    consumablesCountLabel,
    deleteSurgeryAssetsByIdsMutation,
    deviceCountCorrectiveActionTaken,
    expirationDate,
    groupedStatuses,
    isConsumable,
    isDTMScrew,
    isMultipackConsumable,
    isMultipackHardware,
    isRfMultipackConsumable,
    isSpongeConsumable,
    scans,
    serialNumber,
    sizeText,
    toBeDeleted,
    unusedCount,
    versionModelNumber,
    deleteConsumableAssetsByIdsMutation,
    handleDelete,
    setToBeDeleted,
    isApproved,
    accurateDeviceCount,
    assetDescription,
    deviceId,
    catalogNumber,
    lotBatch,
    deviceCount,
    associatedScans,
    hasApproved,
    fileNames,
    isExpired,
    parentCompany,
    longestLabel,
    setLongestLabel,
  } = useStoredAssetLogic({
    assetData,
    surgeryId,
    isProcedureSubmitted,
    isExplanted,
    groupedAssetData,
    printMode,
  })

  return (
    <div className="stored-asset">
      <Snackbar
        open={Boolean(deleteSurgeryAssetsByIdsMutation.error)}
        autoHideDuration={5000}
        onClose={deleteSurgeryAssetsByIdsMutation.reset}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          top: '70px !important',
        }}
      >
        <MuiAlert
          elevation={8}
          variant="filled"
          onClose={deleteSurgeryAssetsByIdsMutation.reset}
          severity="error"
          sx={{ width: '100%' }}
        >
          Failed to delete product(s). Please try again.
        </MuiAlert>
      </Snackbar>

      {toBeDeleted.length > 0 ? (
        <DeleteAssetDialog
          deleteableCount={
            isConsumable ? singleConsumablesCount : toBeDeleted.length
          }
          isLoading={
            isConsumable
              ? deleteConsumableAssetsByIdsMutation.loading
              : deleteSurgeryAssetsByIdsMutation.loading
          }
          handleDelete={handleDelete}
          onCancel={() => setToBeDeleted([])}
          isConsumable={isConsumable}
          isMultipack={isMultipackConsumable}
        />
      ) : null}

      {/* --------------------  Asset Details Card -------------------- */}
      <Box
        className={classNames('assetGroup', {
          isApproved: isExplanted ? false : isApproved,
          isRfConsumable: isRfMultipackConsumable,
          isExpired: isExpired,
          isCountApproved:
            accurateDeviceCount || deviceCountCorrectiveActionTaken,
        })}
        sx={{
          opacity: deleteSurgeryAssetsByIdsMutation.loading ? 0.5 : 1,
        }}
      >
        <AssetDetailsCard
          printMode={printMode}
          isProcedureSubmitted={isProcedureSubmitted}
          startComponent={
            <AssetDetailsStartIcon
              isManualAddition={scans[0].isManualAddition as boolean}
            />
          }
          assetDetails={{
            assetTitle: {
              value: assetDescription,
            },
            assetSizeText: {
              value: sizeText,
            },
            assetTypeLabel: {
              value: getAssetTypeLabel(assetType),
            },
            assetManufacturer: {
              value: parentCompany
                ? `${parentCompany} [${assetData.scans[0]?.companyName}]`
                : assetData.scans[0]?.companyName,
            },
            bidCompanyId: {
              value: assetData.bidCompanyId ?? null,
            },
            deviceId: {
              value: deviceId,
            },
            assetTrayId: {
              value: assetTray,
            },
            modelNumber: {
              value: versionModelNumber,
            },
            catalogNumber: {
              value: catalogNumber,
            },
            lotNumber: {
              value: lotBatch,
            },
            serialNumber: {
              value: serialNumber,
            },
            expirationDate: {
              value: expirationDate,
            },
            deviceCount: {
              value: deviceCount,
            },
            usedCount: {
              value:
                isRfMultipackConsumable ||
                isSpongeConsumable ||
                isMultipackHardware
                  ? assetData.packsUsed
                  : assetData.total,
            },
          }}
          isExpireAccepted={false}
          assetType={assetType}
          isExplanted={isExplanted}
          isDTMScrew={assetData.scans.some((scan) => scan.isDTMScrew)}
        >
          {/* --------------------  Asset SubDetails -------------------- */}
          <>
            {scans
              ?.reduce(
                (acc: (Scan & { key: string; ids: string[] })[], scan) => {
                  if (
                    scan.implantStatus === 'ASSOCIATED_ASSET' ||
                    scan.assetType.toLowerCase() === 'consumable'
                  ) {
                    return acc
                  }

                  let implantOrWastedText
                  if (
                    scan.implantStatus === 'IMPLANTED' ||
                    scan.implantStatus === 'EXPLANTED'
                  ) {
                    implantOrWastedText = scan.implantSite?.toLowerCase() ?? ''
                  } else if (scan.implantStatus === 'WASTED') {
                    implantOrWastedText = scan.wastedReason?.toLowerCase() ?? ''
                  }

                  const groupingKeys = [
                    scan.implantStatus,
                    implantOrWastedText,
                    scan.temperatureOk,
                    scan.packagingOk,
                    scan.implantMemo,
                    scan.samplePreparation,
                    scan.samplePreparationNote,
                    scan.siteLocation,
                  ]

                  const key = createGroupingKey(groupingKeys)

                  const existingScan = acc.find((s) => s.key === key)
                  if (existingScan) {
                    existingScan.count = existingScan.count
                      ? (existingScan.count += scan.count ?? 1)
                      : 1 + (scan.count ?? 1)
                    existingScan.ids.push(scan._id)
                  } else {
                    acc.push({
                      ...scan,
                      key,
                      ids: [scan._id],
                    })
                  }

                  return acc
                },
                []
              )
              .sort((a, b) => {
                const nameA = a.key.toUpperCase()
                const nameB = b.key.toUpperCase()

                if (nameA < nameB) {
                  return -1
                }

                if (nameA > nameB) {
                  return 1
                }

                return 0
              })
              .map((scan, index) => {
                const wastedReasonValue = WASTED_REASON_LIST.find(
                  (reason) => reason.id === scan?.wastedReason
                )?.name

                const explantedReasonValue = EXPLANTED_REASON_LIST.find(
                  (reason) => reason.id === scan?.explantedReason
                )?.name

                let implantOrWastedText
                if (
                  scan.implantStatus === 'IMPLANTED' ||
                  scan.implantStatus === 'EXPLANTED'
                ) {
                  implantOrWastedText = scan.implantSite?.toLowerCase() ?? ''
                } else if (scan.implantStatus === 'WASTED') {
                  implantOrWastedText = wastedReasonValue
                }

                const dispositionAssetLabel =
                  scan.implantStatus === 'NOT_PRESENT'
                    ? 'Not Present'
                    : `${scan.implantStatus?.toLowerCase()} - ${
                        implantOrWastedText ?? ''
                      }${
                        (scan.siteLocation && scan.implantSite === 'Mouth') ||
                        scan.implantSite === 'Other'
                          ? ` - ${scan.siteLocation}`
                          : ''
                      }${
                        scan.explantedReason && scan.explantedReason !== 'OTHER'
                          ? ` - ${explantedReasonValue}`
                          : ''
                      }${
                        scan.explantedReasonNote
                          ? ` - ${scan.explantedReasonNote}`
                          : ''
                      }`

                const updateLongestLabel = () => {
                  const currentLongest = Math.max(
                    dispositionAssetLabel.length,
                    wastedReasonValue ? wastedReasonValue.length : 0
                  )

                  if (currentLongest > longestLabel) {
                    setLongestLabel(currentLongest)
                  }
                }

                updateLongestLabel()

                if (scan.assetType === 'biological') {
                  const renderConditionCheck = (
                    label: string,
                    value: AssetGoodConditionCheck
                  ) => {
                    if (!value) return null

                    let valueResult = null

                    if (value === 'YES') valueResult = '✅'
                    else if (value === 'NO') valueResult = '❌'
                    else return null

                    return (
                      <Grid item sx={{ mb: 1 }}>
                        <Typography
                          variant="h6"
                          sx={{
                            color: value === 'YES' ? 'black' : 'error.main',
                            ml: 1,
                            mr: 1,
                            textTransform: 'capitalize',
                            opacity: 0.8,
                          }}
                        >
                          {`${label}: ${valueResult}`}
                        </Typography>
                      </Grid>
                    )
                  }

                  const renderSamplePreparation = () =>
                    scan.samplePreparation && (
                      <Grid item sx={{ mb: 1 }}>
                        <Grid item>
                          <Typography
                            variant="h6"
                            sx={{
                              color: 'black',
                              ml: 1,
                              mr: 1,
                              textTransform: 'capitalize',
                              opacity: 0.8,
                            }}
                          >
                            {`Sample Preparation: ${Object.entries(
                              SamplePreparationType
                            )
                              .map(([key, val]) =>
                                key === scan.samplePreparation ? val : null
                              )
                              .filter((elem) => elem)} ${
                              scan.samplePreparationNote
                                ? ` - ${scan.samplePreparationNote}`
                                : ''
                            }`}
                          </Typography>
                        </Grid>
                      </Grid>
                    )

                  const renderNote = (label: string, value: string) =>
                    value && (
                      <Grid item sx={{ mb: 1 }}>
                        <Grid item>
                          <Typography
                            variant="h6"
                            sx={{
                              color: 'black',
                              ml: 1,
                              mr: 1,
                              textTransform: 'capitalize',
                              opacity: 0.8,
                            }}
                          >
                            {`${label}: ${value}`}
                          </Typography>
                        </Grid>
                      </Grid>
                    )

                  return (
                    <DispositionedAssetDetails
                      printMode={printMode}
                      key={`${scan.bidAssetId}-${index}`}
                      value={scan.count ?? 1}
                      label={dispositionAssetLabel}
                      longestLabel={longestLabel}
                      handleDelete={
                        canDelete
                          ? () => {
                              setToBeDeleted(scan.ids)
                            }
                          : undefined
                      }
                      editComponent={
                        printMode ? null : (
                          <EditAssetButton
                            isManualAddition={
                              scans[0].isManualAddition as boolean
                            }
                            scanIds={scan.ids}
                            bidAssetId={scan.bidAssetId}
                            assetgroupId={assetData._id}
                            scansArray={scans}
                            isProcedureSubmitted={isProcedureSubmitted}
                            assetTray={assetTray}
                            surgeryId={surgeryId}
                            isMultipackHardware={isMultipackHardware}
                          />
                        )
                      }
                    >
                      {scan.implantStatus !== 'WASTED' &&
                        scan.implantStatus !== 'EXPLANTED' && (
                          <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            sx={{ mt: 0 }}
                          >
                            {renderConditionCheck(
                              'temperature',
                              scan.temperatureOk
                            )}
                            {renderConditionCheck(
                              'packaging intact',
                              scan.packagingOk
                            )}
                            {renderSamplePreparation()}
                            {scan.implantMemo &&
                              renderNote('Scrub tech/note', scan.implantMemo)}
                          </Grid>
                        )}
                    </DispositionedAssetDetails>
                  )
                } else {
                  let scansArray = [] as Scan[]
                  if (scan.count > 1) {
                    scansArray = scans.filter((s) => scan.ids.includes(s._id))
                  } else {
                    scansArray = [scan]
                  }

                  return (
                    <DispositionedAssetDetails
                      key={`${scan.bidAssetId}-${index}`}
                      value={scan.count ?? 1}
                      label={dispositionAssetLabel}
                      longestLabel={longestLabel}
                      handleDelete={
                        canDelete
                          ? () => {
                              setToBeDeleted(scan.ids)
                            }
                          : undefined
                      }
                      editComponent={
                        !printMode &&
                        assetType !== 'biological' &&
                        assetType !== 'consumable' && (
                          <EditAssetButton
                            isManualAddition={
                              scans[0].isManualAddition as boolean
                            }
                            scanIds={scan.ids}
                            bidAssetId={scan.bidAssetId}
                            assetgroupId={assetData._id}
                            scansArray={scansArray}
                            isProcedureSubmitted={isProcedureSubmitted}
                            assetTray={assetTray}
                            surgeryId={surgeryId}
                            isMultipackHardware={isMultipackHardware}
                          />
                        )
                      }
                    />
                  )
                }
              })}

            {consumablesCountLabel()}

            {associatedCount > 0 && (
              <DispositionedAssetDetails
                value={associatedCount}
                label="Utilized associated product"
                handleDelete={
                  canDelete
                    ? () => {
                        setToBeDeleted(associatedScans.map((s: Scan) => s._id))
                      }
                    : undefined
                }
                editComponent={
                  printMode ? null : (
                    <EditAssetButton
                      isManualAddition={scans[0].isManualAddition as boolean}
                      scanIds={associatedScans.map((s: Scan) => s._id)}
                      bidAssetId={assetData.bidAssetId}
                      assetgroupId={assetData._id}
                      scansArray={associatedScans}
                      isProcedureSubmitted={isProcedureSubmitted}
                      assetTray={assetTray}
                      surgeryId={surgeryId}
                      isMultipackHardware={isMultipackHardware}
                    />
                  )
                }
              />
            )}

            {unusedCount > 0 && !isExplanted && !isDTMScrew && (
              <DispositionedAssetDetails value={unusedCount} label="Unused" />
            )}

            {hasApproved && (
              <Box className="approvals">
                {Object.entries(groupedStatuses.APPROVED).map(
                  ([key, statuses]) => {
                    const signatureName = statuses[0].signature?.signature

                    return (
                      <Typography
                        key={key}
                        mt={0.5}
                        variant="subtitle2"
                        className="approved-by-text"
                      >
                        {!isExplanted
                          ? `${statuses.length} Approved by ${signatureName}`
                          : ''}
                      </Typography>
                    )
                  }
                )}
              </Box>
            )}
          </>
          <MediaUpload fileNames={fileNames} />
        </AssetDetailsCard>
      </Box>
    </div>
  )
}

export default StoredAsset
