import {
  ApolloClient,
  LazyQueryExecFunction,
  NormalizedCacheObject,
  OperationVariables,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client'
import { POLL_INTERVAL, POLL_INTERVAL_PROCEDURE } from 'lib/config'
import { captureWarning } from 'lib/monitoring'
import {
  GET_SURGERY_QUERY,
  GET_SURGERIES_QUERY,
  ADD_SURGERY_ASSET_SCANS_MUTATION,
  RESET_SURGERY_STATUS_MUTATION,
  SET_SURGERY_STATUS_MUTATION,
  DELETE_SURGERY_ASSETS_BY_IDS,
  SET_ASSET_STATUS_MUTATION,
  ALL_SURGERY_COMPANIES_QUERY,
  GET_PRODUCT_REPS_QUERY,
  SEND_SURGERY_TO_REP_MUTATION,
  GET_ACCESS_LOGS_QUERY,
  SEND_PRODUCT_REP_INVITE_MUTATION,
  ADD_REP_TO_SURGERY,
  RESET_USER_PASSWORD_MUTATION,
  GET_PROVIDERS_QUERY,
  ADD_SURGERY_INSTRUMENT_TRAYS_MUTATION,
  DELETE_SURGERY_INSTRUMENT_TRAY,
  CHARACTER_SCAN_QUERY,
  BETTERID_LOOKUP_QUERY,
  UPDATE_SURGERY_ASSET_GROUPS_COUNTS_MUTATION,
  COMPANY_SEARCH_QUERY,
  GET_IMPLANT_SITES_QUERY,
  DELETE_CONSUMABLE_ASSETS_MUTATION,
  GET_PRODUCT_COMPANIES_QUERY,
  SEND_PRODUCT_REGISTRATION_EMAILS_MUTATION,
  ATTACH_MEDIA_MUTATION,
  ADD_UNDOCUMENTED_SCAN_MUTATION,
  GET_UNDOCUMENTED_SCANS,
  DELETE_UNDOCUMENTED_SCANS,
  BATCH_ADD_ASSETS_TO_SURGERY,
  ALL_COMPANIES_QUERY,
  GET_ASSET_MEDIA_FILENAMES_QUERY,
  SEARCH_REP_USERS_QUERY,
  GET_RECORD_COMPANY_REPS_QUERY,
  GET_ALL_TRAY_ANALYSES,
  UPLOAD_TRAY_IMAGE_TO_S3_BUCKET,
  CREATE_TRAY_ANALYSIS,
  UPDATE_USER_CORRECTIONS,
  DELETE_TRAY,
  EDIT_DOCUMENTED_ASSETS,
  GENERATE_PRESIGNED_URL_MUTATION,
  GET_TRAY_ITEM_BY_BARCODE,
  EDIT_TRAY_ITEM,
  GET_SURGERY_TRAYS,
  DELETE_TRAY_ITEM,
  GET_TRAY_ITEMS,
  CREATE_TRAY_ITEM_MUTATION,
  BETTERID_LOOKUP_WITH_METADATA,
  PAGINATED_COMPANY_SEARCH,
  SEND_IMPLANT_REPORT_EMAIL_MUTATION,
  GET_INVITE_REP_QR_CODE,
  VALIDATE_INVITE_REP_QR_CODE,
  GET_REPS_QUERY,
  SEND_ASSET_SMS_EMAIL_MUTATION,
  GET_ASSET_MEDIA_FILENAMES,
  DELETE_MEDIA_FILES,
  UPLOAD_ASSET_MEDIA,
  ADD_REFERRING_PHYSICIAN,
  SET_SURGERY_STATUS_AS_STARTED,
  GET_REFERRING_PHYSICIAN,
  CREATE_BETTERID_PRODUCT_EMAIL,
  DELETE_BETTERID_PRODUCT_EMAIL,
  FIND_BETTERID_PRODUCT_EMAILS,
  UPDATE_BETTERID_PRODUCT_EMAIL,
  EDIT_REFERRING_PHYSICIAN,
  CREATE_SURGERY,
  GET_PATIENTS,
  EDIT_SUB_TRAY_ITEM,
  GET_SUB_TRAY_ITEM_BY_ID,
  DELETE_SUB_TRAY_ITEM,
  ASSIGN_TRAY_STATUS_AS_CASE_COMPLETE,
  GET_REFERRING_PHYSICIANS,
  DELETE_REFERRING_PHYSICIANS,
  ASSIGN_TRAY_AS_NURSE_FROM_OR,
  GET_SCRUB_TECHS_QUERY,
  ADD_SCRUB_TECHNICIAN,
  EDIT_SCRUB_TECHNICIAN,
  DELETE_SCRUB_TECHNITIANS,
  ADD_ADDENDUM,
  SEND_SMS_TO_PHYSICIAN,
} from 'lib/apollo/schema'
import {
  Scan,
  AccessLogFilterInput,
  ProcedureStatus,
  BetterIdLookupQuery,
  AssetGroupCount,
  BetterIdCompany,
  BetterIdImplantSite,
  Media,
  UndocumentedScan,
  BatchScanAssetsInput,
  Surgery,
  CreateTrayAnalysisResponse,
  TrayAnalysisInput,
  TrayAnalysis,
  UpdateUserCorrectionsMutationVariables,
  AnalysisResultInput,
  ItemResponse,
  CreateTrayItemInput,
  SearchBetterIdWithMetaDataProps,
  PaginatedCompanySearchProps,
  ImplantReportEmailInput,
  useGetInviteRepQrCodeQueryResponse,
  SendProductRepInviteResponse,
  SendProductRepInviteVariables,
  AddRepToSurgeryResponse,
  AddRepToSurgeryVariables,
  GetRepsQueryResponse,
  GetRepsQueryVariables,
  SendAssetSmsEmailResponse,
  SendAssetSmsEmailVariables,
  DeleteMediaFilesResponse,
  DeleteMediaFilesVariables,
  UploadAssetMediaVariables,
  UploadAssetMediaResponse,
  GetMediaFilesResponse,
  GetMediaFilesVariables,
  AddSurgeryAssetScansResponse,
  BetterIdCreateProductEmailResponse,
  BetterIdCreateProductEmailVariables,
  BetterIdDeleteProductEmailResponse,
  BetterIdFindProductsEmailsResponse,
  BetterIdUpdateProductEmailResponse,
  SendProductRegistrationEmailsResponse,
  SendProductRegistrationEmailsVariables,
  BetterIdUpdateProductEmailVariables,
  EditPhysicianVariables,
  CreatePhysicianVariables,
  CreatePhysicianResponse,
  EditPhysicianResponse,
  ReferringPhysician,
  PaginatedCompanySearchResponse,
  SearchBetterIdWithMetaDataResponse,
  CreateSurgeryInput,
  CreateSurgeryResponse,
  GetPatientsResponse,
  GetPatientsInputs,
  SubItem,
  TUseGetReferringPhysicians,
  SendSurgeryToRepResponse,
  SendSurgeryToRepVariables,
  TUseCompanySearchQueryProps,
  GetRepsQueryProps,
  TGetScrubTechniciansResponse,
  TCreateScrubTechnicianResponse,
  TCreateScrubTechnicianVariables,
  TEditScrubTechnicianResponse,
  TEditScrubTechnicianVariables,
  TDeleteScrubTechniciansResponse,
  TDeleteScrubTechnicicansVariables,
  RecordCompanyResult,
  TAddAddendumResponse,
  TAddAddendumVariables,
  TSendSmsToPhysicianResponse,
  TsendSmsToPhysicianVariables,
} from 'common/types'
import { DispositionData } from 'common/disposition'
import dayjs from 'lib/dayjs'
import { getEndpoint } from './inventory-config'
import { useAuth0 } from 'app/Auth'
import { EditDocumentedScanInput } from 'lib/context/EditDocumentedListContext/EditDocumentedList.types'
import { useState } from 'react'

const useGetSurgeryQuery = (
  surgeryId?: string,
  { disablePolling = false }: { disablePolling?: boolean } = {},
  repCompanyIds?: number[]
) => {
  return useQuery(GET_SURGERY_QUERY, {
    skip: !surgeryId,
    pollInterval: disablePolling ? 0 : POLL_INTERVAL_PROCEDURE,
    fetchPolicy: 'network-only',
    variables: {
      surgeryId,
      repCompanyIds,
    },
    onError: (e: Error) => {
      captureWarning(e)
    },
  })
}

const useGetSurgeriesQuery = (
  {
    before,
    after,
    limit = 50,
    page,
    status,
    isFromDTM,
  }: {
    before?: Date | dayjs.Dayjs
    after?: Date | dayjs.Dayjs
    limit?: number
    page?: number
    status?: ProcedureStatus['name'] | 'READY'
    isFromDTM?: boolean
  } = {},
  {
    disablePolling = false,
    client,
  }: {
    disablePolling?: boolean
    client?: ApolloClient<NormalizedCacheObject>
  } = {}
) => {
  return useQuery(GET_SURGERIES_QUERY, {
    variables: {
      filters: {
        before,
        after,
        limit,
        page,
        status,
        isFromDTM,
      },
    },
    pollInterval: disablePolling ? 0 : POLL_INTERVAL,
    // pollInterval: 0,
    // skipPollAttempt: () => true,
    // pollInterval: 0,
    // skipPollAttempt: () => true,
    fetchPolicy: 'network-only',
    errorPolicy: 'none', // This allows us to retrieve the data if there is an error
    onError: (e: Error) => {
      captureWarning(e)
    },
    ...(client ? { client } : {}),
  })
}

const useAllCompaniesQuery = () =>
  useQuery(ALL_COMPANIES_QUERY, {
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useAllSurgeriesCompaniesQuery = (surgeryId: Surgery['_id']) =>
  useQuery(ALL_SURGERY_COMPANIES_QUERY, {
    variables: {
      surgeryId,
    },
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useGetProductRepsQuery = (companyName?: string) =>
  useQuery(GET_PRODUCT_REPS_QUERY, {
    variables: {
      companyName,
    },
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useAddSurgeryAssetScans = (surgeryId: Surgery['_id'] | undefined) => {
  if (!surgeryId) {
    throw Error('Surgery ID not defined')
  }

  return useMutation<AddSurgeryAssetScansResponse>(
    ADD_SURGERY_ASSET_SCANS_MUTATION,
    {
      refetchQueries: [{ query: GET_SURGERY_QUERY, variables: { surgeryId } }],
      onError: (e: Error) => {
        captureWarning(e)
      },
    }
  )
}

const useAssetGroupsCounts = (
  surgeryId: string,
  assetGroupsCounts: AssetGroupCount[]
) => {
  return useMutation(UPDATE_SURGERY_ASSET_GROUPS_COUNTS_MUTATION, {
    variables: {
      surgeryId,
      assetGroupsCounts,
    },
    refetchQueries: [{ query: GET_SURGERY_QUERY, variables: { surgeryId } }],
    onError: (e: Error) => {
      captureWarning(e)
    },
  })
}

const useAddSurgeryInstrumentTrayScans = (surgeryId: string) => {
  if (!surgeryId) {
    throw Error('Surgery ID not defined')
  }

  const mutation = useMutation(ADD_SURGERY_INSTRUMENT_TRAYS_MUTATION, {
    refetchQueries: [{ query: GET_SURGERY_QUERY, variables: { surgeryId } }],
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

  return mutation
}

const useSetSurgeryStatus = (surgeryId: Surgery['_id'] | undefined) => {
  if (!surgeryId) {
    throw Error('Surgery ID not defined')
  }
  return useMutation(SET_SURGERY_STATUS_MUTATION, {
    refetchQueries: [{ query: GET_SURGERY_QUERY, variables: { surgeryId } }],
    awaitRefetchQueries: true,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })
}

const useSetAssetStatus = (surgeryId?: string) => {
  if (!surgeryId) {
    throw Error('Surgery ID not defined')
  }
  return useMutation(SET_ASSET_STATUS_MUTATION, {
    refetchQueries: [{ query: GET_SURGERY_QUERY, variables: { surgeryId } }],
    awaitRefetchQueries: true,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })
}

const useResetSurgeryStatus = (surgeryId: string | undefined) => {
  if (!surgeryId) {
    throw Error('Surgery ID not defined')
  }
  return useMutation(RESET_SURGERY_STATUS_MUTATION, {
    refetchQueries: [{ query: GET_SURGERY_QUERY, variables: { surgeryId } }],
    awaitRefetchQueries: true,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })
}

const useDeleteSurgeryAssetsByIds = ({
  surgeryId,
  assetIds,
}: {
  surgeryId?: string
  assetIds?: Scan['_id'][]
} = {}) =>
  useMutation(DELETE_SURGERY_ASSETS_BY_IDS, {
    variables: { surgeryId, assetIds },
    refetchQueries: [{ query: GET_SURGERY_QUERY, variables: { surgeryId } }],
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useDeleteConsumableAssetsByIds = ({
  surgeryId,
  assetIds,
  count,
  isMultipack,
}: {
  surgeryId?: string
  assetIds?: Scan['_id'][]
  count?: number
  isMultipack?: boolean
} = {}) =>
  useMutation(DELETE_CONSUMABLE_ASSETS_MUTATION, {
    variables: { count, assetIds },
    refetchQueries: [{ query: GET_SURGERY_QUERY, variables: { surgeryId } }],
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useDeleteSurgeryInstrumentTray = ({
  surgeryId,
}: {
  surgeryId?: string
} = {}) =>
  useMutation(DELETE_SURGERY_INSTRUMENT_TRAY, {
    refetchQueries: [{ query: GET_SURGERY_QUERY, variables: { surgeryId } }],
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useSendSurgeryToRepMutation = () =>
  useMutation<SendSurgeryToRepResponse, SendSurgeryToRepVariables>(
    SEND_SURGERY_TO_REP_MUTATION,
    {
      onError: (e: Error) => {
        captureWarning(e)
        throw e.message
      },
    }
  )

const useGetAccessLogsQuery = (
  filter: AccessLogFilterInput = {},
  page?: number,
  limit?: number
) =>
  useQuery(GET_ACCESS_LOGS_QUERY, {
    variables: {
      filter,
      page,
      limit,
    },
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useSendProductRepInviteMutation = () =>
  useMutation<SendProductRepInviteResponse, SendProductRepInviteVariables>(
    SEND_PRODUCT_REP_INVITE_MUTATION,
    {
      onError: (e: Error) => {
        captureWarning(e)
        throw e
      },
    }
  )

const useResetUserPasswordMutation = (email: string) =>
  useMutation(RESET_USER_PASSWORD_MUTATION, {
    variables: {
      email,
    },
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useGetProvidersQuery = () =>
  useQuery(GET_PROVIDERS_QUERY, {
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useBetterIdLookupQuery = (
  lookupValue?: string,
  {
    onCompleted,
    onError,
    skip = false,
  }: {
    onCompleted?: (data: BetterIdLookupQuery) => void
    onError?: (e: Error) => void
    skip?: boolean
  } = {},
  client?: ApolloClient<NormalizedCacheObject>
) =>
  useQuery<BetterIdLookupQuery>(BETTERID_LOOKUP_QUERY, {
    skip: !lookupValue || skip,
    variables: {
      lookupValue,
    },
    onCompleted,
    onError: (e: Error) => {
      captureWarning(e)
      onError?.(e)
    },
    ...(client ? { client } : {}),
  })

const useBetterIdLookup = () => {
  return useLazyQuery<BetterIdLookupQuery, { lookupValue?: string }>(
    BETTERID_LOOKUP_QUERY
  )
}

const useCharacterScanQuery = (base64ImageStr: string) => {
  return useQuery(CHARACTER_SCAN_QUERY, {
    variables: {
      base64ImageStr,
    },
    onError: (e: Error) => {
      captureWarning(e)
    },
  })
}

export type TUseCompanySearchQueryResponse = {
  companySearch: BetterIdCompany[]
}

const useLazyCompanySearchQuery = () => {
  return useLazyQuery<
    { companySearch: BetterIdCompany[] },
    TUseCompanySearchQueryProps
  >(COMPANY_SEARCH_QUERY, {
    onError: (e: Error) => {
      captureWarning(e)
    },
  })
}

const useCompanySearchQuery = (props: TUseCompanySearchQueryProps) => {
  const { name, companyIds } = props
  return useQuery<{ companySearch: BetterIdCompany[] }>(COMPANY_SEARCH_QUERY, {
    variables: {
      name,
      companyIds,
    },
    skip: (name?.length ?? 0) < 2,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })
}

const usePaginatedCompanySearch = () => {
  return useLazyQuery<
    PaginatedCompanySearchResponse,
    { data: PaginatedCompanySearchProps }
  >(PAGINATED_COMPANY_SEARCH, {
    onError: (e: Error) => {
      captureWarning(e)
    },
  })
}

const useSearchBetterIdWithMetaData = () => {
  return useLazyQuery<
    SearchBetterIdWithMetaDataResponse,
    { data: SearchBetterIdWithMetaDataProps }
  >(BETTERID_LOOKUP_WITH_METADATA, {
    onError: (e: Error) => {
      captureWarning(e)
    },
  })
}

const useGetImplantSitesQuery = () => {
  return useQuery<{ getImplantSites: BetterIdImplantSite[] }>(
    GET_IMPLANT_SITES_QUERY,
    {
      onError: (e: Error) => {
        captureWarning(e)
      },
    }
  )
}

const useGetProductCompaniesQuery = (companyNames: String[]) =>
  useQuery(GET_PRODUCT_COMPANIES_QUERY, {
    variables: {
      companyNames,
    },
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useSendProductRegistrationEmailsMutation = (productsIds: number[]) => {
  return useMutation<
    SendProductRegistrationEmailsResponse,
    SendProductRegistrationEmailsVariables
  >(SEND_PRODUCT_REGISTRATION_EMAILS_MUTATION, {
    onError: (e: Error) => {
      captureWarning(e)
      throw e
    },
    refetchQueries: [
      {
        query: FIND_BETTERID_PRODUCT_EMAILS,
        variables: { productsIds },
      },
    ],
  })
}

const useAddUndocumentedScanMutation = (surgeryId: string, userId: string) => {
  return useMutation(ADD_UNDOCUMENTED_SCAN_MUTATION, {
    variables: {
      surgeryId,
      userId,
    },
    refetchQueries: [
      { query: GET_UNDOCUMENTED_SCANS, variables: { surgeryId } },
    ],
    onError: (e: Error) => {
      captureWarning(e)
    },
  })
}

const useGetUndocumentedScansQuery = (
  surgeryId?: string,
  stopPolling?: boolean
) => {
  return useQuery<{ getUndocumentedScans: UndocumentedScan[] }>(
    GET_UNDOCUMENTED_SCANS,
    {
      skip: !surgeryId,
      ...(!stopPolling && { pollInterval: POLL_INTERVAL_PROCEDURE }),
      variables: {
        surgeryId,
      },
      onError: (e: Error) => {
        captureWarning(e)
      },
      fetchPolicy: 'network-only',
    }
  )
}

const useDeleteUndocumentedScanMutation = (
  surgeryId?: string,
  scanIds?: string[]
) => {
  return useMutation<{
    deleteUndocumentedScans?: {
      deletedIds?: string[]
    }
  }>(DELETE_UNDOCUMENTED_SCANS, {
    variables: {
      scanIds,
    },
    refetchQueries: [
      {
        query: GET_UNDOCUMENTED_SCANS,
        variables: { surgeryId: surgeryId },
      },
    ],
    onError: (e: Error) => {
      captureWarning(e)
    },
  })
}

const useBatchAddAssetsToSurgery = (
  surgeryId: string,
  disposition?: DispositionData,
  scans?: BatchScanAssetsInput[]
) => {
  return useMutation<{
    batchAddAssetsToSurgery?: {
      addedScanIds?: string[]
    }
  }>(BATCH_ADD_ASSETS_TO_SURGERY, {
    variables: {
      surgeryId,
      disposition,
      scans,
    },
    refetchQueries: [{ query: GET_SURGERY_QUERY, variables: { surgeryId } }],
    onError: (e: Error) => {
      captureWarning(e)
    },
  })
}

const useAttachMediaMutation = (assetId: String, media: Media[]) =>
  useMutation(ATTACH_MEDIA_MUTATION, {
    variables: {
      assetId,
      media,
    },
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useGetAssetMediaFilenamesQuery = (assetId: string) =>
  useMutation(GET_ASSET_MEDIA_FILENAMES_QUERY, {
    variables: {
      assetId,
    },
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useSearchRepUsersQuery = (name?: string, bidCompanyId?: number) =>
  useQuery(SEARCH_REP_USERS_QUERY, {
    variables: {
      name,
      bidCompanyId,
    },
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useSendAssetSmsEmailMutation = (surgeryId: string) => {
  const [sendAssetSmsEmail, { loading, data, error }] = useMutation<
    SendAssetSmsEmailResponse,
    SendAssetSmsEmailVariables
  >(SEND_ASSET_SMS_EMAIL_MUTATION, {
    onError: (e: Error) => {
      captureWarning(e)
    },
    refetchQueries: [{ query: GET_SURGERY_QUERY, variables: { surgeryId } }],
  })

  return {
    sendAssetSmsEmail,
    loading,
    data: data?.sendAssetSmsEmail,
    error,
  }
}

const useGetRepsQuery = (props: GetRepsQueryProps) => {
  return useQuery<GetRepsQueryResponse, GetRepsQueryVariables>(GET_REPS_QUERY, {
    variables: props,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })
}

export type TuseGetRecordCompanyRepsQueryResponse = {
  getRecordCompanyReps: RecordCompanyResult[]
}

const useGetRecordCompanyRepsQuery = (surgeryId: Surgery['_id']) =>
  useQuery<TuseGetRecordCompanyRepsQueryResponse>(
    GET_RECORD_COMPANY_REPS_QUERY,
    {
      variables: {
        surgeryId,
      },
      onError: (e: Error) => {
        captureWarning(e)
      },
    }
  )

const useCreateBetterIdProductEmail = (productsIds: number[]) => {
  return useMutation<
    BetterIdCreateProductEmailResponse,
    BetterIdCreateProductEmailVariables
  >(CREATE_BETTERID_PRODUCT_EMAIL, {
    onError: (e: Error) => {
      captureWarning(e)
    },
    refetchQueries: [
      {
        query: FIND_BETTERID_PRODUCT_EMAILS,
        variables: { productsIds },
      },
    ],
  })
}

const useFindBetterIdProductsEmails = (variables: {
  productsIds: number[]
}) => {
  return useQuery<
    BetterIdFindProductsEmailsResponse,
    { productsIds: number[] }
  >(FIND_BETTERID_PRODUCT_EMAILS, {
    variables,
    onError: (e: Error) => {
      captureWarning(e)
      throw e
    },
    skip: variables.productsIds.length === 0,
  })
}

const useUpdateBetterIdProductEmail = (productsIds: number[]) => {
  return useMutation<
    BetterIdUpdateProductEmailResponse,
    BetterIdUpdateProductEmailVariables
  >(UPDATE_BETTERID_PRODUCT_EMAIL, {
    onError: (e: Error) => {
      captureWarning(e)
      throw e
    },
    refetchQueries: [
      {
        query: FIND_BETTERID_PRODUCT_EMAILS,
        variables: { productsIds },
      },
    ],
  })
}

const useDeleteBetterIdProductEmail = (productsIds: number[]) => {
  return useMutation<
    BetterIdDeleteProductEmailResponse,
    { productEmailId: number }
  >(DELETE_BETTERID_PRODUCT_EMAIL, {
    onError: (e: Error) => {
      captureWarning(e)
      throw e
    },
    refetchQueries: [
      {
        query: FIND_BETTERID_PRODUCT_EMAILS,
        variables: { productsIds },
      },
    ],
  })
}

const useGetAllTrayAnalysesQuery = ({
  skip = 0,
  limit = 4,
  id = null,
  createdAt = null,
}: {
  skip?: number
  limit?: number
  id?: number | null
  createdAt?: Date | null
}) => {
  const input = { skip, limit, id, createdAt }
  const { data, loading, error, fetchMore } = useQuery(GET_ALL_TRAY_ANALYSES, {
    variables: { input },
  })

  const loadMore = () => {
    fetchMore({
      variables: {
        skip: skip,
        take: 4,
      },
    })
  }

  // Extracting trayAnalyses and totalCount from the response
  const trayAnalyses = data ? data.getAllTrayAnalyses.trayAnalyses : []
  const totalCount = data ? data.getAllTrayAnalyses.totalCount : 0

  return {
    trayAnalyses,
    totalCount,
    loading,
    error,
    loadMore,
  }
}

const useUploadTrayImageToS3BucketMutation = () => {
  const [uploadImageToS3Bucket, { loading, error }] = useMutation(
    UPLOAD_TRAY_IMAGE_TO_S3_BUCKET
  )

  const uploadToS3Bucket = (input: File) => {
    return uploadImageToS3Bucket({ variables: { input } })
  }

  return [uploadToS3Bucket, { loading, error }] as const
}

const useCreateTrayAnalysisMutation = () => {
  const [createTrayAnalysisMutation, { loading, error }] = useMutation<
    CreateTrayAnalysisResponse,
    { input: TrayAnalysisInput }
  >(CREATE_TRAY_ANALYSIS)

  const createTrayAnalysis = async (
    inputData: TrayAnalysisInput
  ): Promise<{ data: TrayAnalysis | null; error: string | null }> => {
    try {
      const { data } = await createTrayAnalysisMutation({
        variables: { input: inputData },
      })
      return { data: data?.createTrayAnalysis || null, error: null }
    } catch (error: any) {
      return { data: null, error: error.message || 'An error occurred' }
    }
  }

  return { createTrayAnalysis, loading, error }
}

const useUpdateUserCorrectionsMutation = () => {
  const [updateUserCorrectionsMutation, { loading, error }] = useMutation<
    { updateUserCorrections: TrayAnalysis },
    UpdateUserCorrectionsMutationVariables
  >(UPDATE_USER_CORRECTIONS)

  const updateUserCorrections = async (
    trayId: number,
    userCorrections: AnalysisResultInput[]
  ): Promise<TrayAnalysis> => {
    const { data } = await updateUserCorrectionsMutation({
      variables: { trayId, userCorrections },
    })

    if (!data) {
      throw new Error('No data received from updateUserCorrections mutation')
    }

    return data.updateUserCorrections
  }

  return { updateUserCorrections, loading, error }
}

const useDeleteTray = () => {
  const [deleteTrayMutation, { loading }] = useMutation(DELETE_TRAY)

  const deleteTray = async (trayId: number) => {
    try {
      const { data } = await deleteTrayMutation({
        variables: { trayId },
      })
      return data.deleteTray // Return true/false based on server response
    } catch (error) {
      console.error('Error deleting tray:', error)
      return false // Return false in case of error
    }
  }

  return { deleteTray, loading }
}

const useEditDocumentedAssets = (
  surgeryId: Surgery['_id'],
  isSponge?: boolean,
  disposition?: DispositionData,
  scans?: EditDocumentedScanInput[]
) => {
  return useMutation<{
    editDocumentedAssets?: {
      success: boolean
      message: string
    }
  }>(EDIT_DOCUMENTED_ASSETS, {
    variables: {
      surgeryId,
      disposition,
      scans,
      isSponge,
    },
    refetchQueries: [{ query: GET_SURGERY_QUERY, variables: { surgeryId } }],
    onError: (e: Error) => {
      captureWarning(e)
    },
  })
}

export const useGeneratePresignedUrlMutation = () => {
  const [generatePresignedUrlMutation, { loading, error, data }] = useMutation(
    GENERATE_PRESIGNED_URL_MUTATION
  )

  const generatePresignedUrl = async (
    key: string
  ): Promise<string | undefined> => {
    try {
      const response = await generatePresignedUrlMutation({
        variables: { key },
      })
      return response.data?.generatePresignedUrl
    } catch (error) {
      console.error('Error generating presigned URL:', error)
      return undefined
    }
  }

  return {
    loading,
    error,
    presignedUrl: data?.generatePresignedUrl,
    generatePresignedUrl,
  }
}

export const useDeleteTrayItem = () => {
  const [deleteTrayMutation, { loading }] = useMutation(DELETE_TRAY_ITEM)

  const deleteTray = async (trayId: number) => {
    try {
      const { data } = await deleteTrayMutation({
        variables: { trayId },
      })
      return data.deleteTrayItem
    } catch (error) {
      console.error('Error deleting tray:', error)
      return false
    }
  }

  return { deleteTray, loading }
}

export const useGetAllTrayItems = () => {
  const { loading, error, data, refetch } = useQuery<{
    getTrayItems: ItemResponse[]
  }>(GET_TRAY_ITEMS)

  return {
    loading,
    error,
    trayItems: data?.getTrayItems ?? [],
    refetch,
  }
}

export function useSurgeryItemsQuery(surgeryId: string) {
  const { loading, error, data } = useQuery(GET_SURGERY_TRAYS, {
    variables: { surgeryId },
  })

  return {
    loading,
    error,
    surgeryItems: data ? data.getSurgeryItems : [],
  }
}

export function useUpdateTrayItem(surgeryId?: string) {
  const [editTrayItemMutation] = useMutation(EDIT_TRAY_ITEM)

  const editTrayItem = async (
    input: ItemResponse
  ): Promise<{ editTrayItem: boolean }> => {
    try {
      const { data } = await editTrayItemMutation({
        variables: { input },
        refetchQueries: [
          {
            query: GET_SURGERY_TRAYS,
            variables: { surgeryId: surgeryId },
          },
        ],
      })
      return data
    } catch (error) {
      console.error('Error editing tray item:', error)
      return { editTrayItem: false }
    }
  }

  return {
    editTrayItem,
  }
}

// To not have to use the InventoryProvider in the Undocumented/ Documented List surgery files,
// I mocked how Apollo sends the GraphQL request
export function useUpdateTrayItemFunction() {
  const { getAccessToken } = useAuth0()
  const GRAPHQL_ENDPOINT = getEndpoint()

  const editTrayItem = async (input: ItemResponse): Promise<boolean> => {
    const mutation = `
    mutation EditTrayItem($input: EditTrayItemInput!) {
      editTrayItem(input: $input)
    }
    `

    const variables = { input }

    try {
      const token = await getAccessToken()
      const response = await fetch(GRAPHQL_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          query: mutation,
          variables,
        }),
      })

      const result = await response.json()

      if (result.errors) {
        console.error('GraphQL errors:', result.errors)
        return false
      }

      return result.data.editTrayItem
    } catch (error) {
      console.error('Error editing tray item:', error)
      return false
    }
  }

  return {
    editTrayItem,
  }
}

export function useEditTrayItemJSONDetailsFunction() {
  const { getAccessToken } = useAuth0()
  const GRAPHQL_ENDPOINT = getEndpoint()

  const editTrayItemJSONDetails = async (input: {
    id: number
    productDetails: string
  }): Promise<boolean> => {
    const mutation = `
    mutation EditTrayItemJSONDetails($input: EditTrayItemJSONDetails!) {
      editTrayItemJSONDetails(input: $input)
    }
    `

    const variables = { input }

    try {
      const token = await getAccessToken()
      const response = await fetch(GRAPHQL_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          query: mutation,
          variables,
        }),
      })

      const result = await response.json()

      if (result.errors) {
        console.error('GraphQL errors:', result.errors)
        return false
      }

      return result.data.editTrayItem
    } catch (error) {
      console.error('Error editing tray item JSON details: ', error)
      return false
    }
  }

  return {
    editTrayItemJSONDetails,
  }
}

export function useGetTrayItemByBarcode() {
  const [getTrayItem, { loading, error, data, refetch }] = useLazyQuery(
    GET_TRAY_ITEM_BY_BARCODE
  )

  return {
    data,
    loading,
    error,
    trayItem: data ? data.getTrayItemByBarcode : null,
    getTrayItem,
    refetch,
  }
}

export const useCreateTrayItemMutation = () => {
  const [createTrayItemMutation, { loading, error }] = useMutation(
    CREATE_TRAY_ITEM_MUTATION
  )

  const createTrayItem = async (input: CreateTrayItemInput) => {
    try {
      const { data } = await createTrayItemMutation({
        variables: { input },
      })
      return data.createTrayItem
    } catch (error) {
      console.error('Error creating tray item:', error)
      throw error
    }
  }

  return { createTrayItem, loading, error }
}

export const useGetInviteRepQrCodeQuery = (
  canAddAsset: boolean,
  surgeryId: Surgery['_id']
) => {
  return useQuery<useGetInviteRepQrCodeQueryResponse>(GET_INVITE_REP_QR_CODE, {
    fetchPolicy: 'network-only',
    variables: { canAddAsset, surgeryId },
    pollInterval: 0,
    skipPollAttempt: () => true,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })
}

const useAddRepToSurgeryMutation = () => {
  const [mutate, { data, loading, error }] = useMutation<
    AddRepToSurgeryResponse,
    AddRepToSurgeryVariables
  >(ADD_REP_TO_SURGERY, {
    onError: (e: Error) => {
      captureWarning(e)
    },
  })
  return { mutate, data, loading, error }
}

export const useValidateInviteRepQrCodeQuery = (token: string) => {
  return useQuery(VALIDATE_INVITE_REP_QR_CODE, {
    variables: { token },
    onError: (e: Error) => {
      captureWarning(e)
    },
  })
}

const useSendImplantReportEmailMutation = () =>
  useMutation<any, { implantReportEmailInput: ImplantReportEmailInput }>(
    SEND_IMPLANT_REPORT_EMAIL_MUTATION,
    {
      onError: (e: Error) => {
        captureWarning(e)
        throw e
      },
    }
  )

const useGetAssetMediaFiles = (filenames: string[]) => {
  return useQuery<GetMediaFilesResponse, GetMediaFilesVariables>(
    GET_ASSET_MEDIA_FILENAMES,
    {
      variables: { filenames },
      skip: !Boolean(filenames.length > 0),
      fetchPolicy: 'no-cache',
    }
  )
}

const useDeleteMediaFiles = ({ surgeryId }: { surgeryId: string }) => {
  return useMutation<DeleteMediaFilesResponse, DeleteMediaFilesVariables>(
    DELETE_MEDIA_FILES,
    {
      refetchQueries: [{ query: GET_SURGERY_QUERY, variables: { surgeryId } }],
    }
  )
}

const useUploadAssetMedia = () => {
  return useMutation<UploadAssetMediaResponse, UploadAssetMediaVariables>(
    UPLOAD_ASSET_MEDIA
  )
}

const useGetReferringPhysicians = ({
  search,
  limit,
  skip,
}: TUseGetReferringPhysicians = {}) => {
  const variables = {
    ...(search && { search }),
    ...(limit && { limit }),
    ...(skip && { skip }),
  }

  return useQuery<
    {
      getPhysicians: { physicians: ReferringPhysician[]; totalCount: number }
    },
    {
      search?: string
      limit?: number
      skip?: number
    }
  >(GET_REFERRING_PHYSICIANS, {
    variables,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })
}

const useGetReferringPhysician = (lastName?: string) =>
  useQuery<{ getPhysician: ReferringPhysician[] }>(GET_REFERRING_PHYSICIAN, {
    variables: {
      lastName,
    },
    onError: (e: Error) => {
      captureWarning(e)
    },
    skip: lastName?.length === 0,
  })

const useAddReferringPhysician = () =>
  useMutation<CreatePhysicianResponse, CreatePhysicianVariables>(
    ADD_REFERRING_PHYSICIAN,
    {
      onError: (e: Error) => {
        captureWarning(e)
        throw e
      },
    }
  )

const useEditReferringPhysician = () =>
  useMutation<EditPhysicianResponse, EditPhysicianVariables>(
    EDIT_REFERRING_PHYSICIAN,
    {
      onError: (e: Error) => {
        captureWarning(e)
        throw e
      },
    }
  )

const useDeleteReferringPhysicians = () =>
  useMutation<
    { deletePhysicians: { success: boolean; message: string } },
    { physicianIds: string[] }
  >(DELETE_REFERRING_PHYSICIANS, {
    onError: (e: Error) => {
      captureWarning(e)
      throw e
    },
  })

const useCreateGuestMutation = () => {
  const { getAccessToken } = useAuth0()
  const GRAPHQL_ENDPOINT = getEndpoint()
  const [loading, setLoading] = useState(false)

  const addGuest = async (input: any) => {
    setLoading(true)

    const mutation = `
    mutation CreateGuest($createGuestInput: CreateGuestArgs!) {
      createGuest(createGuestInput: $createGuestInput) {
        id
        name
        company
        phone
        email
      }
    }
    `

    try {
      const token = await getAccessToken()
      const response = await fetch(GRAPHQL_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          query: mutation,
          variables: { createGuestInput: input },
        }),
      })

      const result = await response.json()

      if (result.errors) {
        console.error('GraphQL errors:', result.errors)
        return null
      }

      return result.data?.createGuest || null
    } catch (error) {
      console.error('Error adding guest user:', error)
      return null
    } finally {
      setLoading(false)
    }
  }

  return {
    addGuest,
    loading,
  }
}

const useSetSurgeryStatusAsStarted = () =>
  useMutation<any, { surgeryId: Surgery['_id'] }>(
    SET_SURGERY_STATUS_AS_STARTED,
    {
      refetchQueries: [{ query: GET_SURGERIES_QUERY }],
    }
  )
const useGetSubTrayItemById = (): {
  loading: boolean
  error: any
  subTrayItem: SubItem | null
  getSubTrayItem: LazyQueryExecFunction<any, OperationVariables>
  refetch: () => void
} => {
  const [getSubTrayItem, { loading, error, data, refetch }] = useLazyQuery(
    GET_SUB_TRAY_ITEM_BY_ID
  )

  return {
    loading,
    error,
    subTrayItem: data ? data.getSubTrayItemById : null,
    getSubTrayItem,
    refetch,
  }
}

const useDeleteSubTrayItemById = () => {
  const [deleteSubTrayItemById, { loading }] = useMutation(DELETE_SUB_TRAY_ITEM)

  const deleteSubTray = async (subTrayItemId: number) => {
    try {
      const { data } = await deleteSubTrayItemById({
        variables: { subTrayItemId },
      })
      return data.deleteSubTrayItem
    } catch (error) {
      console.error('Error deleting sub-tray:', error)
      return false
    }
  }

  return {
    loading,
    deleteSubTray,
  }
}

const useEditSubTrayItemMutation = () => {
  const [editSubTrayItemMutation] = useMutation(EDIT_SUB_TRAY_ITEM)

  const editSubTrayItem = async (input: {
    id: number
    productDetails: string
  }): Promise<boolean> => {
    try {
      const { data } = await editSubTrayItemMutation({ variables: { input } })
      return data.editSubTrayItem
    } catch (error) {
      console.error('Error editing sub-tray item:', error)
      return false
    }
  }

  return {
    editSubTrayItem,
  }
}

const useCreateSurgery = () =>
  useMutation<CreateSurgeryResponse, CreateSurgeryInput>(CREATE_SURGERY, {
    onError: (e: Error) => {
      captureWarning(e)
      throw e
    },
  })

const useGetPatients = (variables: GetPatientsInputs) =>
  useQuery<GetPatientsResponse>(GET_PATIENTS, {
    variables,
    onError: (e: Error) => {
      captureWarning(e)
    },
  })

const useAssignTrayStatusAsCaseCompleteMutation = () => {
  const [assignTrayStatusAsCaseCompleteMutation] = useMutation(
    ASSIGN_TRAY_STATUS_AS_CASE_COMPLETE
  )

  const assignTrayStatusAsCaseComplete = async (
    id: number
  ): Promise<boolean> => {
    try {
      const { data } = await assignTrayStatusAsCaseCompleteMutation({
        variables: { id },
      })
      return data.assignTrayStatusAsCaseComplete
    } catch (error) {
      console.error('Error editing sub-tray item:', error)
      return false
    }
  }

  return {
    assignTrayStatusAsCaseComplete,
  }
}

const useAssignTrayAsNurseFromOR = () => {
  const [assignTrayAsNurseFromORMutation] = useMutation(
    ASSIGN_TRAY_AS_NURSE_FROM_OR
  )

  const assignTrayAsNurseFromOR = async (
    input: ItemResponse
  ): Promise<{ assignTrayAsNurseFromOR: boolean }> => {
    try {
      const { data } = await assignTrayAsNurseFromORMutation({
        variables: { input },
      })
      return data
    } catch (error) {
      console.error('Error editing tray item:', error)
      return { assignTrayAsNurseFromOR: false }
    }
  }

  return {
    assignTrayAsNurseFromOR,
  }
}

const useGetScrubTechniciansQuery = () => {
  return useQuery<TGetScrubTechniciansResponse>(GET_SCRUB_TECHS_QUERY, {
    onError: (e: Error) => {
      captureWarning(e)
    },
  })
}

const useCreateScrubTechnicianMutation = () =>
  useMutation<TCreateScrubTechnicianResponse, TCreateScrubTechnicianVariables>(
    ADD_SCRUB_TECHNICIAN,
    {
      onError: (e: Error) => {
        captureWarning(e)
        throw e
      },
    }
  )

const useEditScrubTechnicianMutation = () => {
  return useMutation<
    TEditScrubTechnicianResponse,
    TEditScrubTechnicianVariables
  >(EDIT_SCRUB_TECHNICIAN, {
    onError: (e: Error) => {
      captureWarning(e)
      throw e
    },
  })
}

const useDeleteScrubTechniciansMutation = () =>
  useMutation<
    TDeleteScrubTechniciansResponse,
    TDeleteScrubTechnicicansVariables
  >(DELETE_SCRUB_TECHNITIANS, {
    onError: (e: Error) => {
      captureWarning(e)
      throw e
    },
  })

const useAddAddendumMutation = () =>
  useMutation<TAddAddendumResponse, TAddAddendumVariables>(ADD_ADDENDUM, {
    onError: (e: Error) => {
      captureWarning(e)
      throw e
    },
  })

const useSendSmsToPhysicianMutation = () => {
  return useMutation<TSendSmsToPhysicianResponse, TsendSmsToPhysicianVariables>(
    SEND_SMS_TO_PHYSICIAN,
    {
      onError: (e: Error) => {
        captureWarning(e)
        throw e
      },
    }
  )
}

export {
  useAddAddendumMutation,
  useGetSurgeryQuery,
  useGetSurgeriesQuery,
  useAllCompaniesQuery,
  useGetProductRepsQuery,
  useAddSurgeryAssetScans,
  useAssetGroupsCounts,
  useResetSurgeryStatus,
  useSetSurgeryStatus,
  useSetAssetStatus,
  useDeleteSurgeryAssetsByIds,
  useSendSurgeryToRepMutation,
  useGetAccessLogsQuery,
  useSendProductRepInviteMutation,
  useAddRepToSurgeryMutation,
  useResetUserPasswordMutation,
  useGetProvidersQuery,
  useAddSurgeryInstrumentTrayScans,
  useDeleteSurgeryInstrumentTray,
  useCharacterScanQuery,
  useBetterIdLookupQuery,
  useBetterIdLookup,
  useCompanySearchQuery,
  useGetImplantSitesQuery,
  useDeleteConsumableAssetsByIds,
  useGetProductCompaniesQuery,
  useSendProductRegistrationEmailsMutation,
  useAttachMediaMutation,
  useAddUndocumentedScanMutation,
  useGetUndocumentedScansQuery,
  useDeleteUndocumentedScanMutation,
  useBatchAddAssetsToSurgery,
  useAllSurgeriesCompaniesQuery,
  useGetAssetMediaFilenamesQuery,
  useSearchRepUsersQuery,
  useGetRecordCompanyRepsQuery,
  useGetAllTrayAnalysesQuery,
  useUploadTrayImageToS3BucketMutation,
  useCreateTrayAnalysisMutation,
  useUpdateUserCorrectionsMutation,
  useDeleteTray,
  useEditDocumentedAssets,
  useSearchBetterIdWithMetaData,
  usePaginatedCompanySearch,
  useSendImplantReportEmailMutation,
  useGetRepsQuery,
  useSendAssetSmsEmailMutation,
  useDeleteMediaFiles,
  useUploadAssetMedia,
  useGetAssetMediaFiles,
  useAddReferringPhysician,
  useSetSurgeryStatusAsStarted,
  useGetReferringPhysicians,
  useGetReferringPhysician,
  useCreateBetterIdProductEmail,
  useFindBetterIdProductsEmails,
  useUpdateBetterIdProductEmail,
  useDeleteBetterIdProductEmail,
  useEditReferringPhysician,
  useCreateSurgery,
  useGetPatients,
  useEditSubTrayItemMutation,
  useDeleteSubTrayItemById,
  useGetSubTrayItemById,
  useAssignTrayStatusAsCaseCompleteMutation,
  useDeleteReferringPhysicians,
  useCreateGuestMutation,
  useLazyCompanySearchQuery,
  useAssignTrayAsNurseFromOR,
  useGetScrubTechniciansQuery,
  useCreateScrubTechnicianMutation,
  useEditScrubTechnicianMutation,
  useDeleteScrubTechniciansMutation,
  useSendSmsToPhysicianMutation,
}
