import { Alert, Snackbar, Typography } from '@mui/material'
import { Helmet } from 'react-helmet'

import { ConfirmLoadMultipleScrewsModal } from 'components/organisms/ConfirmLoadMultipleScrewsModal/ConfirmLoadMultipleScrewsModal'
import { ChooseTrayLocationModal } from 'components/organisms/ChooseTrayLocationModal/ChooseTrayLocationModal'
import SelectedTrayModal from '../../../components/organisms/SelectedTrayModal/SelectedTrayModal'
import EditTrayModal from 'components/organisms/EditTrayModal/EditTrayModal'
import SelectTray from './SelectTray/SelectTray'
import Scan from './Scan/Scan'
import InventoryProvider from 'lib/apollo/inventory-config'
import { useSPDHook } from './SPD.logic'
import { useSPD } from './SPD.context'
import { ItemResponse } from 'common/types'
import './SPD.scss'
import { useSPDScan } from './Scan/Scan.context'
import CaptureTrayDialog from 'components/organisms/CaptureTrayDialog/CaptureTrayDialog'
import LoadSPDTrayMap from './LoadSPDTrayMap/LoadSPDTrayMap'
import classNames from 'classnames'

const SPD = () => {
  const {
    navbarHeight,
    trayData,
    showTrayDetails,
    setShowTrayDetails,
    showSurgeryChangeModal,
    setShowSurgeryChangeModal,
    showChooseTrayLocationModal,
    setShowChooseTrayLocationModal,
    snackbarOpen,
    snackbarState,
    snackbarMessage,
    snackbarIcon,
    handleSnackbarClose,
    trayDetails,
    onlyUseManualWorkflow,
    activeStep,
  } = useSPD()
  const { handleNextStep, steps, handleStepClick } = useSPDHook()
  const { isAdditionalDeviceCountModalOpen } = useSPDScan()

  return (
    <div
      style={{ minHeight: `calc(100vh - ${navbarHeight}px)` }}
      className="spd-container_wrapper"
    >
      <Helmet>
        <title>SPD</title>
      </Helmet>
      <Typography variant="h1" mt="20px">
        {onlyUseManualWorkflow
          ? 'Manual Tray Configuration'
          : 'Automated Workflow'}
      </Typography>
      <div className="stepper">
        {steps.map((label) => (
          <div
            key={label}
            className={classNames('step_label', {
              active: activeStep === steps.indexOf(label),
            })}
            onClick={() => handleStepClick(steps.indexOf(label))}
          >
            {label}
          </div>
        ))}
      </div>
      {trayDetails ? (
        <div className="stepper_content">
          {onlyUseManualWorkflow ? (
            <LoadSPDTrayMap trayDetails={trayDetails} onNext={handleNextStep} />
          ) : (
            <>
              {activeStep === 0 && <Scan onNext={handleNextStep} />}
              {activeStep === 1 && (
                <InventoryProvider>
                  <CaptureTrayDialog onNext={handleNextStep} />
                </InventoryProvider>
              )}
              {activeStep === 2 && (
                <LoadSPDTrayMap
                  trayDetails={trayDetails}
                  onNext={handleNextStep}
                />
              )}
            </>
          )}
        </div>
      ) : (
        <SelectTray />
      )}

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarState}
          sx={{ width: '100%' }}
          icon={snackbarIcon}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <ConfirmLoadMultipleScrewsModal
        open={isAdditionalDeviceCountModalOpen}
        onClose={() => {
          return
        }}
      />

      <InventoryProvider>
        <EditTrayModal
          open={showSurgeryChangeModal}
          onClose={() => setShowSurgeryChangeModal(false)}
          trayData={trayData?.trayItem as ItemResponse}
        />

        <ChooseTrayLocationModal
          open={showChooseTrayLocationModal}
          onClose={() => setShowChooseTrayLocationModal(false)}
        />

        <SelectedTrayModal
          open={showTrayDetails}
          onClose={() => setShowTrayDetails(false)}
        />
      </InventoryProvider>
    </div>
  )
}

export default SPD
