import { ANALYTICS, getTitleMap } from 'app/ROUTES_CONSTANTS'
import { getUserRoleString, useUser } from 'app/User'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useDashboardContext } from 'lib/context/DashboardContext'
import { useEffect, useState } from 'react'
import { useIsRouteActive } from 'lib/utils/isRouteActive'
import { useLocation, useNavigate } from 'react-router-dom'
import { useOrganizationSettings } from 'lib/context/OrganizationSettingsContext'

export const useFloatingBarLogic = () => {
  const flags = useFlags()
  const navigate = useNavigate()
  const location = useLocation()
  const organizationSettings = useOrganizationSettings()

  const { isActive } = useIsRouteActive()
  const { user, isNurseAdmin } = useUser()
  const { setDarkMode, darkMode } = useDashboardContext()

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const userRoleString = user && getUserRoleString(user)
  const isSPD = user?.roles?.includes('MGAT_SPD')

  const getDynamicTitle = (location: ReturnType<typeof useLocation>) => {
    const titleMap = getTitleMap(organizationSettings)
    const rootPath = location.pathname.slice(1) as keyof typeof titleMap
    return titleMap[rootPath] ?? 'Platform'
  }

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const handleLogout = () => {
    setIsMenuOpen(false)
    navigate('/logout')
  }

  const handleNavigate = (route: string) => {
    setIsMenuOpen(false)
    navigate(`/${route}`)
  }

  const handleLogoBtnClick = () =>
    handleNavigate(isSPD ? 'tray-management' : 'surgeries')

  const handleThemeMode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDarkMode(e.target.checked)
  }

  useEffect(() => {
    setIsMenuOpen(false)
  }, [location])

  return {
    user,
    isSPD,
    flags,
    darkMode,
    location,
    isMenuOpen,
    dynamicTitle: getDynamicTitle(location),
    isNurseAdmin,
    userRoleString,
    isDashboardActive: isActive(ANALYTICS),
    PhysiciansTitle: organizationSettings.physiciansTitle,
    setDarkMode,
    handleLogout,
    handleNavigate,
    getDynamicTitle,
    handleThemeMode,
    handleMenuToggle,
    handleLogoBtnClick,
  }
}
