import { ArrowBack } from '@mui/icons-material'
import { Button, Skeleton, Typography } from '@mui/material'

import TrayMapSelector from 'components/organisms/TrayMapSelector/TrayMapSelector'

import useTrayContentLogic from './TrayContent.logic'

import './TrayContent.scss'

interface TrayContentProps {
  trayIndex: number
}

const TrayContent: React.FC<TrayContentProps> = ({ trayIndex }) => {
  const { subTray, handleBack, saveChanges } = useTrayContentLogic(trayIndex)

  return (
    <div className="tray-content_wrapper">
      <div className="tray-content_header">
        <div className="left_container">
          <Button
            variant="outlined"
            size="small"
            className="back_button"
            onClick={handleBack}
          >
            <ArrowBack sx={{ mr: 0.5 }} /> BACK
          </Button>

          <Typography variant="h1">View Tray Content</Typography>
        </div>

        <div className="right_container">
          {!subTray ? (
            <Skeleton variant="rounded" animation="wave" className="skeleton" />
          ) : (
            <img src={subTray.trayImg} alt={subTray.trayType} />
          )}
        </div>

        <div className="save_container">
          {subTray && (
            <Button
              variant="contained"
              color="primary"
              onClick={saveChanges}
              className="save_button"
            >
              Save
            </Button>
          )}
        </div>
      </div>

      {!subTray && (
        <Typography className="error" variant="body1">
          This sub tray does not exist.
        </Typography>
      )}

      {subTray && subTray.trayType !== 'instrument' && (
        <div className="map_container">
          <TrayMapSelector trayType={subTray.trayType} isInOR={false} />
        </div>
      )}

      {subTray?.trayType === 'instrument' && (
        <Typography variant="body1" className="instrument-text">
          Tray maps are not available for instrument trays.
        </Typography>
      )}

      {subTray?.description && (
        <div className="description_container">
          <Typography variant="h6">Description</Typography>
          <Typography variant="body1">{subTray.description}</Typography>
        </div>
      )}
    </div>
  )
}

export default TrayContent
