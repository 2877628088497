import { ChangeEvent } from 'react'

import { useUser } from 'app/User'
import { useTrayConfigurationContext } from 'views/TrayManagementLayout/TrayManagement/TrayManagement.context'

import { Filter, SubFilter } from './TrayManagementFilters.types'
import { TrayType } from '../AddTraySelectType/AddTraySelectType.types'
import { Option } from 'common/types'

const GENERAL_SUBFILTERS: SubFilter[] = [
  { title: 'Ready for Pickup', filterValue: 'readyForPickup' },
  { title: 'Approved', filterValue: 'approved' },
  { title: 'Rejected', filterValue: 'rejected' },
  { title: 'Dropped Off', filterValue: 'droppedOff' },
  { title: 'Ready to Fill', filterValue: 'readyToFill' },
  { title: 'Assigned', filterValue: 'assigned' },
]

const HOSPITAL_SUBFILTERS: SubFilter[] = [
  { title: 'Ready to Fill', filterValue: 'readyToFill' },
  { title: 'Assigned', filterValue: 'assigned' },
]

const NURSE_FILTERS: Filter[] = [
  {
    title: 'Hospital Trays',
    filterValue: 'facility',
    subFilters: HOSPITAL_SUBFILTERS,
  },
]

const useTrayManagementFiltersLogic = () => {
  const { isNurse } = useUser()
  const {
    setSelectedFilters,
    selectedFilters,
    setSelectedCategory,
    selectedCategory,
    trayIdFilterValue,
    setTrayIdFilterValue,
    companyFilterValue,
    setCompanyFilterValue,
    trays,
  } = useTrayConfigurationContext()

  const vendorOptions: Option[] = Array.from(
    new Set(
      trays.filter((tray) => tray.companyName).map((tray) => tray.companyName)
    )
  )
    .filter((companyName): companyName is string => companyName !== undefined)
    .map((companyName) => ({
      id: companyName,
      name: companyName,
    }))

  const listOfFilters: Filter[] = [
    { title: 'All Trays', filterValue: '', subFilters: GENERAL_SUBFILTERS },
    ...(isNurse ? NURSE_FILTERS : []),
    {
      title: 'Loaner Trays',
      filterValue: 'loaner',
      subFilters: GENERAL_SUBFILTERS,
    },
    {
      title: 'Consignment Trays',
      filterValue: 'consigned',
      subFilters: GENERAL_SUBFILTERS,
    },
  ]

  /**
   * Handle filter click event
   * @param filter - The filter value to set
   */
  const handleFilterClick = (filter: Filter): void => {
    const isSameCategory = filter.filterValue === selectedCategory

    setSelectedCategory(filter.filterValue as TrayType['category'])

    const subFilterValues = filter.subFilters?.map((sf) => sf.filterValue) || []
    const allSubFiltersSelected = subFilterValues.every((sf) =>
      selectedFilters.has(sf)
    )

    if (allSubFiltersSelected && isSameCategory) {
      setSelectedFilters(new Set())
    } else {
      setSelectedFilters(new Set(subFilterValues))
    }
  }

  /**
   * Handle subfilter click event
   * @param subFilterValue - The value of the subfilter
   */
  const handleSubFilterClick = (
    subFilterValue: SubFilter['filterValue']
  ): void => {
    const newSelectedFilters = new Set(selectedFilters)
    if (newSelectedFilters.has(subFilterValue)) {
      newSelectedFilters.delete(subFilterValue)
    } else {
      newSelectedFilters.add(subFilterValue)
    }
    setSelectedFilters(newSelectedFilters)
  }

  /**
   * Handle tray ID filter change event
   * @param e - The change event from the input
   */
  const handleTrayIdFilterChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setTrayIdFilterValue(e.target.value)
  }

  return {
    listOfFilters,
    handleFilterClick,
    handleSubFilterClick,
    selectedFilters,
    selectedCategory,
    trayIdFilterValue,
    handleTrayIdFilterChange,
    vendorOptions,
    companyFilterValue,
    setCompanyFilterValue,
  }
}

export default useTrayManagementFiltersLogic
